import React from 'react';
import './Footer.css';
import ProgramImage from '../img/image005-1024x190-1.jpg'

export default function Footer() {
    return (
        <div className='footer container'>
            <img src={ProgramImage} alt="Espa 2014-2020 Logo" />
        </div>
    )
}
