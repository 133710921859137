import './Contact.css'
import { useState, useRef } from 'react'
import emailjs from '@emailjs/browser';

export default function Contact() {

  const [age, setAge] = useState('')
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [number, setNumber] = useState('')
  const [emailSent, setEmailSent] = useState(false)

  const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_dddxcwm', 'template_ody61ix', form.current, 'QGr4V0j_zb2yP1rn7')
      .then((result) => {
        console.log(result.text);
        setEmailSent(true)
      }, (error) => {
        console.log(error.text);
      });
  };

  return (
    <div className="contact">
      <div className='work-hours'>
        <h2><span>ΩΡΑΡΙΟ</span> ΛΕΙΤΟΥΡΓΙΑΣ</h2>
        <ul className='work-hours-list'>
          <li className='work-hours-list-item'>
            <span>Δευτέρα:</span>
            <span>8:30 - 14:30</span>
          </li>
          <li className='work-hours-list-item'>
            <span>Τρίτη:</span>
            <span>8:30 - 14:30 & 18:00 - 21:00</span>
          </li>
          <li className='work-hours-list-item'>
            <span>Τετάρτη:</span>
            <span>8:30 - 14:30</span>
          </li>
          <li className='work-hours-list-item'>
            <span>Πέμπτη:</span>
            <span>8:30 - 14:30 & 18:00 - 21:00</span>
          </li>
          <li className='work-hours-list-item'>
            <span>Παρασκευή:</span>
            <span>8:30 - 14:30 & 18:00 - 21:00</span>
          </li>
          <li className='work-hours-list-item'>
            <span>Σάββατο:</span>
            <span>9:00 - 14:00</span>
          </li>
          <li className='work-hours-list-item'>
            <span>Κυριακή:</span>
            <span>Κλειστά</span>
          </li>
        </ul>
        <p>Κατά προτεραιότητα εξυπηρετούνται τα ραντεβού</p>
        <p>Το ωράριο μπορεί να μεταβληθεί ανάλογα με τις υποχρεώσεις του γιατρόυ (χειρουργεία, επείγοντα περιστατικά, κατ'οίκον επισκέψεις)</p>
      </div>
      <div className='contact-form'>
        <h2>ΦΟΡΜΑ ΕΠΙΚΟΙΝΩΝΙΑΣ</h2>
        {!emailSent && (
          <form ref={form} onSubmit={sendEmail}>
            <label htmlFor='name'>
              <span className={name === "" ? "" : "move-span-up"}>Ονοματεπώνυμο:</span>
              <input
                type="text"
                name="name"
                required
                aria-required
                aria-label='Όνομα'
                onChange={(e) => setName(e.target.value)} />
            </label>
            <label htmlFor='number'>
              <span className={number === "" ? "" : "move-span-up"}>Τηλέφωνο:</span>
              <input
                type="text"
                name="number"
                required
                aria-required
                aria-label='Τηλέφωνο'
                onChange={(e) => setNumber(e.target.value)} />
            </label>
            <label htmlFor='email'>
              <span className={email === "" ? "" : "move-span-up"}>Email:</span>
              <input
                type="email"
                name="email"
                required
                aria-required
                aria-label='email'
                onChange={(e) => setEmail(e.target.value)} />
            </label>
            <label htmlFor='text'>
              <span className={age === "" ? "" : "move-span-up"}>Ηλικία:</span>
              <input
                name="age"
                type="text"
                required
                aria-required
                aria-label='Ηλικία'
                onChange={(e) => setAge(e.target.value)} />
            </label>
            <label className='form-text-area-label' htmlFor="message">
              <textarea className='form-text-area' name='message' placeholder='Περιγράψτε την ασθένεια σας...' aria-label='Πειγραφή αιτήματος'></textarea>
            </label>
            <button className='contact-button'>ΑΠΟΣΤΟΛΗ</button>
          </form>
        )}
        {emailSent && <p className='email-sent-message'>Σύντομα ο γιατρός σας θα επικοινωνίσει μαζί σας</p>}
      </div>
      <div className='location'>
        <h2>ΒΡΕΙΤΕ ΜΑΣ ΣΤΟ <span>ΧΑΡΤΗ</span></h2>
        <p>3ος όροφος <span>Ορφέως 12 Κομοτηνή</span></p>
        <iframe className="map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3005.725624455781!2d25.401717415312!3d41.11868297928973!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14adedc8cbbc2ebb%3A0x7683c27111d6622!2zzpXOoc6nzpHOnSDOnM6fzqXOo86kzpHOps6RLCDOp86VzpnOoc6fzqXOoc6Tzp_OoywgzprOn86czp_OpM6Xzp3Olw!5e0!3m2!1sen!2sgr!4v1674138487136!5m2!1sen!2sgr" style={{ border: 0 }} loading='lazy' referrerpolicy="no-referrer-when-downgrade" title='Λογότυπο επιχειρησιακού προγράμματος ΕΣΠΑ 2014-2020'></iframe>
      </div>
    </div>
  )
}
