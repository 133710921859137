import logo from "../img/logo.png"
import "./Navbar.css"

import { useState } from "react"

export default function Navbar() {
    const [open, setOpen] = useState(false)

    const handleClick = () => {
        if (open) {
            return setOpen(false)
        }
        setOpen(true)
    }


    return (
        <nav className={open ? "navbar open" : "navbar"}>
            <div className="social-container">
                <div className="social container">
                    <a href="https://www.linkedin.com/in/dr-emoustafa" className="social-link" aria-label="linkedin page">
                        <ion-icon name="logo-linkedin"></ion-icon>
                    </a>
                    <a href="https://www.facebook.com/emoustafa.surg" className="social-link" aria-label="facebook page">
                        <ion-icon name="logo-facebook"></ion-icon>
                    </a>
                    <a href="https://www.instagram.com/emoustafa.surg/" className="social-link" aria-label="instagram page">
                        <ion-icon name="logo-instagram"></ion-icon>
                    </a>
                </div>
            </div>
            <div className="logo-and-details-container" >
                <div className="logo-and-details container" aria-label="logo and details" role="contentinfo">
                    <img className="logo" src={logo} alt="Λογότυπο ιστοσελίδας του Ερχάν Μουσταφά" />
                    <h1 className="logo-heading"><span>Ερχάν Μουσταφά, MD, MSc</span><span>Γενικός Χειρουργός</span></h1>
                    <div className="icon-and-details-container">
                        <div className="icon-and-details">
                            <ion-icon name="call"></ion-icon>
                            <div className="details">
                                <p className="detail-title">Καλέστε τώρα:</p>
                                <a className="phone-number" href="tel:2531083979">2531 083 979</a>
                                <a className="phone-number" href="tel:6978 327 774">6978 327 774</a>
                            </div>
                        </div>
                        <div className="icon-and-details">
                            <ion-icon name="location"></ion-icon>
                            <div className="details">
                                <p className="detail-title">Διεύθυνση:</p>
                                <p className="detail-description">Ορφέως 12</p>
                                <p className="detail-description">Κομοτηνή 69132</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </nav>
    )
}
