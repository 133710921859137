import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom"

// Components
import Navbar from './components/Navbar';
import Contact from './components/Contact'
import Footer from './components/Footer';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Navbar />
        <Contact />
        <Footer />
      </BrowserRouter>

    </div>
  );
}

export default App;
